import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import useAccountContext from '@/hooks/context/useAccountContext';
import TikTokAPI from '@/network/TikTokAPI';
import { handleApiError } from '@/utility/api';

import Loading from '../components/utility/Loading';

const TikTokCallbackPage = () => {
  const { accountId, refetchAccount } = useAccountContext();

  const [params] = useSearchParams();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const authCode = useMemo(() => params.get('auth_code'), [params]);

  const loginToTikTok = useCallback(async () => {
    setIsLoading(true);
    try {
      if (!accountId || !authCode) return;

      await TikTokAPI.loginToTikTok({ accountId, authCode });
      await refetchAccount();
      window.opener.postMessage('TikTok logged in', '*');
      window.close();
    } catch (error: unknown) {
      handleApiError({ error });
    } finally {
      setIsLoading(false);
    }
  }, [accountId, authCode, refetchAccount]);

  useEffect(() => {
    loginToTikTok();
  }, [loginToTikTok]);

  return <>{isLoading && <Loading />}</>;
};

export default TikTokCallbackPage;
